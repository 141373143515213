/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.7.1/dist/jquery.min.js
 * - /npm/vue@2.7.16/dist/vue.min.js
 * - /npm/clipboard@2.0.11/dist/clipboard.min.js
 * - /npm/jquery-color@3.0.0/dist/jquery.color.min.js
 * - /gh/gorilahe/goui@main/webuploader.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
